$lightPink: rgb(213, 0, 211);
$darkPink: rgb(222, 0, 195);

body {
  margin: 0;
  padding: 0;
  font-family: 'Exo 2', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  text-align: center;
  min-height: 100vh;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    background: 
      linear-gradient(90deg, transparent 50%, $lightPink 50%, $lightPink), 
      linear-gradient(82deg, transparent 50%, $darkPink 50%, $darkPink), 
      linear-gradient(67deg, transparent 50%, $lightPink 50%, $lightPink), 
      linear-gradient(52deg, transparent 50%, $darkPink 50%, $darkPink), 
      linear-gradient(37deg, transparent 50%, $lightPink 50%, $lightPink), 
      linear-gradient(22deg, transparent 50%, $darkPink 50%, $darkPink), 
      linear-gradient(7deg, transparent 50%, $lightPink 50%, $lightPink), 
      linear-gradient(-8deg, transparent 50%, $darkPink 50%, $darkPink), 
      linear-gradient(-23deg, transparent 50%, $lightPink 50%, $lightPink), 
      linear-gradient(-38deg, transparent 50%, $darkPink 50%, $darkPink), 
      linear-gradient(-53deg, transparent 50%, $lightPink 50%, $lightPink), 
      linear-gradient(-68deg, transparent 50%, $darkPink 50%, $darkPink), 
      linear-gradient(-83deg, transparent 50%, $lightPink 50%, $lightPink), 
      linear-gradient(-90deg, transparent 50%, $darkPink 50%, $darkPink);
    background-position: 0% 0%;
    background-size: 200% 100%;
    height: 100%;
    width: 50%;
    top: 0;
    bottom: 0;
  }

  &:after {
    right: 50%;
  }
  &:before {
    right: 0%;
    transform: rotate(180deg);
  }

  .headline {
    padding-top: 3rem;
    margin: 0;
    z-index: 100;
    position: relative;
    font-size: calc(32px + 2.5vmin);
    text-shadow: 4px 3px 4px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: white;
    display: flex;
    justify-content: center;
    
    @media (min-width: 576px) {
      font-size: calc(40px + 5vmin);
      padding-top: 4rem;
    }
  }
  p {
    position: relative;
    z-index: 100;color: white;
    margin-top: 0;
    font-size: .85rem;
    
    @media (min-width: 576px) {
      padding-bottom: 2rem; 
    }
  }
}
.App-wrapper {
  margin-bottom: 1rem;
  position: relative;
  z-index: 100;
  min-height: 100%;
  max-width: 90%;
  margin: 1rem auto 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: .5rem;
  
  @media (min-width: 576px) {
    margin-top: 3rem;
    max-width: 65%;
    padding: 1rem;
  }
}
.btn {
  background-color: white;
  border-radius: .25rem;
  border: none;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  color: lighten(black, 10%);
  font-size: 3rem;
  font-weight: 700;
  margin: 1rem 0;
  outline: none;
  padding: .5rem;
  transition: all .25s cubic-bezier(0, 0.26, 0, 1.18);
  width: 35%;
  
  &:focus,
  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.19), -1px 2px 2px rgba(0, 0, 0, 0.23);
    cursor: pointer;
  }
  
  @media (min-width: 576px) {
    margin: 1.5rem;
    padding: 1.25rem;
    width: auto;
  }
  &-enter {
    opacity: 0.01;
    transform: scale(1);
  }
  &-enter-active {
    opacity: 1;
    transform: scale(1.1 );
    transition: all 300ms ease-out;
  }
  &-exit {
    opacity: 1;
    transform: scale(1.1 );
  }
  &-exit-active {
    opacity: 0.01;
    transform: scale(1);
    transition: all 300ms ease-out;
  }
}
@for $i from 1 through 30 {
  .btn:nth-child(#{$i}n) {
    transition-delay: #{$i * 0.05}s;
  }
}
.App-footer {
  padding-top: 4rem;
  color: lighten(black, 15%);
  position: relative;
  z-index: 200;

  p {
    margin: 0;
    font-size: .85rem;
    padding: 1.5rem;
    
    @media (min-width: 576px) {
      max-width: 65%;
      margin: 0 auto;
    }

    a {
      color: lighten(black, 15%);
    }
  }
}